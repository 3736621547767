import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { SharedService } from '../../layouts/shared-service';
import { AuthService, DBService, DownLoadService } from '../../service';
import { ActivatedRoute, Router } from '@angular/router';
import { DropDownService } from '../../service/drop-down.service';
import { User } from '../../model/types';
import { AnalysisKey, AuthLog, Logs, PreviousAnalysis, RDSAnalysisData, RDSAuditData } from '../../model/analysis-details.model';
import Utils from '../../utility/Utils';
import { MatDateRangeInput, MatDatepicker } from '@angular/material/datepicker';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { FormControl } from '@angular/forms';
import { AuthNewService } from '../../service/auth-new.service';
import { Subscription } from 'rxjs';
import { StorageService } from '../../service/storage.service';
import { delay } from 'rxjs/operators';

import { formatDate } from '@angular/common';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [{
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  },
  { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class UserComponent implements OnInit {
  date = new FormControl(moment());
  pageTitle = 'User Account';
  signedInUser: any;
  loading = false;
  username: string;
  email: string;
  reportKey = '';
  fileLink;
  logdata: Array<AuthLog> = [];
  users: any[] = [];
  auditAnalysisKeys: RDSAuditData[] = [];
  pAfileLink;
  loginSelected = '';
  ctrlValueMonth;
  ctrlValueYear;
  monthSelected = '';
  maxYear;
  temp_loginData: any;
  temp_analysisData: any;
  clicked = false;
  months = ['January', 'Feburary', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  pageLoaded = false;
  title = 'Loading';
  @ViewChild('datePicker') public dp;
  role = this.storageService.get('access');
  public startDate ='none'
  public endDate='none'
  userAttributes
  illegalUser: boolean
  showTable: boolean = false
  private rdsAuditDataSubscription: Subscription;
  private currentLoginUser: Subscription;
  maxDate = new Date();
  auditFlag:boolean=false;
  dateRangeStart:moment.Moment;
  dateRangeEnd:moment.Moment;
  
  constructor(private _sharedService: SharedService,
    private authService: AuthNewService,
    private router: Router,
    private dbService: DBService,
    private storageService:StorageService) {
    // this._sharedService.emitChange(this.pageTitle);
   
  
  }

  async ngOnInit() {
    this.userAttributes = this.storageService.get('access')
    this.authService.getCurrentUser();
    this.currentLoginUser = this.authService.getCurrentLoginUser.subscribe( (user: any) => {
      this.signedInUser = user
     
      if (!this.signedInUser) {
        this.router.navigate(['/extra-layout/signin']);
        return;
      }
     else if (this.userAttributes === 'PI') {
        this.illegalUser = true;
      }
          
    });
  
     if (this.userAttributes == 'GA'|| this.userAttributes == 'SU'|| this.userAttributes == 'QA'){
      this.illegalUser = false;
      this.showTable = true;  
      this.auditFlag=true    
        this.dbService.getAuditAnalysis(this.role, this.startDate ,this. endDate ,this.auditFlag);
        this.rdsAuditDataSubscription = this.dbService.rdsAuditData.subscribe(
          (data: RDSAuditData[]) => { 
            this.auditAnalysisKeys = data;  
           
          },(error) => {
            console.error('Error fetching audit analysis:', error);
          }
        );           
    } 
  
  }

  clearSelectedDates(){
    this.auditAnalysisKeys = [];
   this.dateRangeEnd=null;
   this.dateRangeStart=null  
    this.startDate = 'none';
    this.endDate = 'none';
    this.dbService.getAuditAnalysis(this.role, this.startDate, this.endDate,this.auditFlag)
  
  }

  passSelectedDates() {
    this.auditAnalysisKeys = []
  this.dbService.getAuditAnalysis(this.role, this.startDate, this.endDate,this.auditFlag)
  
  }

  selectedDates() {
     this.startDate = this.dateRangeStart.format('MM/DD/YYYY');
    this.endDate = this.dateRangeEnd.format('MM/DD/YYYY');
    this.passSelectedDates();
  }

 

  ngOnDestroy() {
    // Unsubscribe from the observable to avoid memory leaks
    if (this.rdsAuditDataSubscription) {
      this.rdsAuditDataSubscription.unsubscribe();
    }
    if (this.currentLoginUser) {
      this.currentLoginUser.unsubscribe();
    }
  }
}


