import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthNewService } from '../../../service/auth-new.service';
import { delay } from 'rxjs/operators';
import { access } from 'fs';
import { StorageService } from '../../../service/storage.service';
import * as CryptoJS from 'crypto-js';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-user-sign-in',
  templateUrl: './user-sign-in.component.html',
  styleUrls: ['./user-sign-in.component.scss']
})
export class UserSignInComponent implements OnInit, OnDestroy {

  signinForm!: FormGroup;
  codeForm!: FormGroup;
  submitted = false;
  username!: string;
  password!: string;
  code!: string;
  user!: CognitoUser;
  userAuthenticated = false;
  mfaFlag = false;
  userGroup = new BehaviorSubject<string>('');
  hide = true;
  error: string = '';
  userRole: string;
  MfaUser
  private currentLoginUser: Subscription;
  resendEnabled: boolean = false;
  resendTimeout: any;
  codeReceived
  accountBlocked: boolean = false;
  passwordtype: string;
  customAttributes;
  PasswordUpdateRequired;
  UserURL: string = environment.serverUrl + '/user-profile';
  userDetails;
  email;
  phoneNumber;

  constructor(public formBuilder: FormBuilder, public router: Router, private authservice: AuthNewService,
    private activatedroute: ActivatedRoute,
    private http: HttpClient,
    private storageService: StorageService) {
    this.resendTimeout = setTimeout(() => {
      this.resendEnabled = true;
    }, 20000);
  }

  ngOnInit(): void {
    // this.error = '';
    this.activatedroute.queryParams.subscribe(params => {
      this.passwordtype = params['passtype'];
      this.error = params['error'];
    });
    this.resendEnabled = false;
    const inf = this.authservice.userLogInf;
    this.signinForm = this.formBuilder.group({
      username: [inf?.uname ?? '', Validators.required],
      password: [inf?.pswrd ?? '', Validators.required]
    });

    this.codeForm = this.formBuilder.group({
      code: ['', Validators.required]
    });
  }
  get formControls() { return this.signinForm.controls; }
  get formControls1() { return this.codeForm.controls; }

  signInNew() {

    this.submitted = true;
    this.userAuthenticated = false;
    if (this.signinForm.invalid) {
      return;
    }

    this.username = this.signinForm.value.username;
    this.password = this.signinForm.value.password;

    this.customAttributes = {
      apiType: 'login',
      hashpassword: CryptoJS.SHA256(this.password).toString(),
    };


    this.authservice.signIn(this.username, this.password, this.customAttributes);

    this.authservice.signinError.subscribe((error: any) => {
      if (error) {
        console.log(error)
        this.submitted = false;

        if (error instanceof Error && error.message.includes('You have crossed the signin attempts')) {
          this.error = 'Your account has been blocked. Please contact support.';
        }
        else if (error instanceof Error && error.message.includes('days_left')) {

          let errorMessage = error.message;
          let objectString = errorMessage.substring(errorMessage.indexOf('{'), errorMessage.lastIndexOf('}') + 1);
          let object = JSON.parse(objectString);
          let objectAsString = JSON.stringify(object);

          let jsonObject = JSON.parse(objectAsString);
          let daysLeft = jsonObject.days_left;

          const queryParams: any = { daysLeft: daysLeft };
          const navigationExtras: NavigationExtras = {
            queryParams: queryParams
          };
          this.router.navigate(['/extra-layout/password-validity'], navigationExtras);
        }
        else {
          this.error = 'Username or Password is incorrect.';
        }

      } else {
        this.error = '';
      }
    })

    this.authservice.accountBlocked.subscribe((blocked) => {
      this.accountBlocked = blocked;
    });

    this.authservice.user.subscribe((user: any) => {
      if (user) {

        if (user.challengeName === 'SMS_MFA') {
          this.codeReceived = user.challengeParam['CODE_DELIVERY_DESTINATION']

          this.mfaFlag = true;
        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.router.navigate(['/extra-layout/new-password']);
        }
        else {

          const storedData = this.storageService.get('access');
          const userId = this.storageService.get('username')
          let params = new HttpParams();
          params = params.append('userId', userId);

          this.http.get(this.UserURL, { params: params }).subscribe(res => {

            this.userDetails = res;
            this.email = this.userDetails.email;
            this.phoneNumber = this.userDetails.phone_number;

            if (this.email == '' || this.phoneNumber == '') {
              this.router.navigate(['default-layout/user-info'])
            }
            else if (storedData == 'QA') {
              this.router.navigate(['default-layout/user-details'])
            }
            else 
                this.router.navigate(['default-layout/previous-analysis'])
          })
         
        }
      }
    })
  }

  confirmationCode() {
    this.code = this.codeForm.value.code;
    // this.authservice.confirmCode(this.code);
    this.authservice.confirmCode(this.code, (error, newUser) => {
      if (error) {
        // Handle error when confirmation code is incorrect
        console.error(error);
        this.error = 'Incorrect Confirmation Code';
      } else {

        if (newUser?.signInUserSession) {
          this.userAuthenticated = true;
          this.user = newUser;

          this.authservice.getMFACurrentUser();
          this.currentLoginUser = this.authservice.MfaUser.subscribe((user) => {
            this.userRole = this.storageService.get('access');

            if (this.userRole == 'QA') {
              this.router.navigate(['default-layout/user-details']);
            } else {
              this.router.navigate(['default-layout/previous-analysis']);
            }
          });
        }
      }
    })

  }

  clearError() {
    this.error = '';
  }
  resendOTP(): void {
    this.error = null
    this.signInNew();
    this.resendEnabled = false;
    clearTimeout(this.resendTimeout);
    this.resendTimeout = setTimeout(() => {
      this.resendEnabled = true;
    }, 20000);

  }



  ngOnDestroy() {
    // Unsubscribe from the observable to avoid memory leaks
    clearTimeout(this.resendTimeout);
    if (this.currentLoginUser) {
      this.currentLoginUser.unsubscribe();
    }
  }
}
