import { organizationId } from "aws-sdk/clients/auditmanager";

export enum FileObjectStatus {
    NotStarted,
    Uploading,
    Uploaded,
    Canceled,
    Deleted,
    Failed
}

export class FileObject {
    status = FileObjectStatus.NotStarted;
    type: string;
    name: any;
    files: File;
    isValid: boolean;
    isFileNameValid?: boolean =true;
    fileNameError?: boolean = false;
    fileSizeError?: boolean = false;
    sheetNameValid?: boolean = true;
    showheaderError?: boolean = false;
    missingColumns
    
    constructor(public file: File, type: string, isValid: boolean) {
        this.type = type;
        this.name = this.file.name;
        this.isValid = isValid;
        this.files = file;


    }
}

export class Uploads {
    uploadType: string;
    index: number;
    path: string;
    files: any;
}
export class Analyte {
    analyteName: string;
    uploadObjs: Uploads[];
    tempPath: string;
    constructor(name) {
        this.analyteName = name;
        this.uploadObjs = [];
    }
}
export class AnalysisDetails {
    userId: string;
    analysisId: string;
    analysisStatus?: string;
    analysisType?: string;
    analysisSubtype?: string;
    study?:string;
    analysisSubtype_ADA?: string;
    report_template?: string;
    projectCode?: string;
    regressionModel?: string;
    lts80_dialog?: string;
    lts20_dialog?: string;
    lloq?: string;
    uloq?: string;
    extraction_dialog?: string;
    benchtop_dialog?: string;
    freeze_thaw_dialog?: string;
    first_patient_dialog?: string;
    sampleMatrix?: string;
    antiCoagulant?: string;
    species?: string;
    samplePrep?: string;
    analyteNames: string[];
    sampleDate: string;
    analytes?: Analyte[];
    weighting_factor?: string;
    STD_conc_arr: string[];
    anchor_point_1?: string;
    anchor_point_2?: string;
    Dilution_QC_conc?: string;
    Low_QC_conc?: string;
    Mid_QC_conc?: string;
    High_QC_conc?: string;
    sample_volume?: string;
    report_id: string;
    method_id: string;
    concomitant_name: string;
    comed_conc: string;
    benchTop_Temp: string;
    noOfcycles: string;
    benchtop_hours: string;
    frozen_temp: string;
    longTerm_frozen_temp: string;
    longTerm_storage_days: string;
    protocol_id: string;
    thawing_temp: string;
    normalityTestData: string;
    screening_cutpoint: string;
    confirmatory_cutpoint: string;
    concentrationUnit: string;
    tukeyCoefficient: string[];
    negativeControlValues: string;
    nabDataset: string;
    multipleAnalytes: string;
    method_type: string;
    ada_subtype: string;
    ada_subtype_cutpoint: string;
    regressionType: string;
    PL: string;
    CVThreshold: string;
    selectedValidationTableIds: string[];
    selectedAssayTableIds: string[];
    selectedGeneralTableIds: string[];
    re_type: string;
    re_value: string;
    tableParamValue: string[];
    files: string[];
    file_path: string;
    analyteName: string[];
    selectedGeneralTableName: string[];
    selectedAssayTableName: string[];
    selectedValidationTableName: string[];
    thresholdLayout: string;
    Lower_Slope_Value: string;
    Higher_Slope_Value: string;
    LLOQ_ULOQ_Inter_prec_CV_Back_Calc: string;
    Gen_Inter_prec_CV_Back_Calc: string;
    displayCq: boolean;
    displayBackCalculated: boolean;
    QualityControls: QualityControls;
    BackCalculated: BackCalculated;
    Cq: Cq;
    targetName: string;
    Gen_Intra_Inter_Acc_RE_Back_Calc: string;
    validators: boolean;
    group: string;
    organization: string;
    userRole: string;
    userName: string;
    removed_sample_id: {};
    executionId: string;
    commentText: string;
    constructor(userId) {
        this.userId = userId;
        this.analysisId = Math.floor(new Date().getTime() / 1000.0).toString();
        this.analysisType = '';
        this.analytes = [];
    }
}

export interface QualityControls {
    LLOQ_QC: string,
    Low_QC: string,
    Mid_QC: string,
    High_QC: string,
    ULOQ_QC: string
}

export interface Cq {
    Intra_prec_CV_Cq: string;

}
export interface BackCalculated {
    Gen_Intra_prec_CV_Back_Calc: string;
    LLOQ_ULOQ_Intra_prec_CV_Back_Calc: string;
}

export interface UploadedFiles {
    type: string,
    name: string
}

export interface DropDowns {
    key: string;
    values: DropDown[]
}

export interface DropDown {
    name: string;
    desc: string;
}


export interface SearchResult {
    userId: string;
    analysisId: string;
    analysisDate?: string;
    analysisType: string;
    analysisSubtype?: string;
    analysisTypeDisplay?: string;
    analysisResult?: string;
    analysisResultFile?: string;
    analysisResultData?: string;
    analysisStatus?: string;
    analysisDetails?: string;
    selectedTables?: string;
    analyteNames?: string;
    projectNames?: string;
    tableData?: string;
    tableReselection?: number;
    AnalysisResultGraph?: string;
    Messages?: string[];
    Tables?: NewTable[];
    MissingTables: string[];
    output_file_path: string;
    output_file_name: string;
    file_path: string;
    output_result_path: string;
    error_message: string;
    status_code: number;
    audit_file_path: string;
    pdf_data?: string;
    targetName: string;
    projectCode: string;
    userName: string;
    analysis_date: string;
    GraphData: string;
    removed_sample_id: any;
    executionId: string;
    commentText: { run_id: string,selected_samples:string[], comments: string[] }[];
    iteration:number;
    initial_graph_data
}
export class NewTable {

    table_type?: string;
    table_title?: string;
    analysis_type?: string;
    table_data?: ResponseTable;
    missing_columns?: string;
    threshold?: Dictonary[];
    result?: Result;

}

export class Dictonary {
    key: string;
    value: string;
}

export class Result {
    item?: Item[];
    green: string;
    yellow: string;
    red: string;
}

export class Item {
    color: string;
    message: string[];
}
export class ResponseTable {
    columns: string[];
    data: string[];

}

export class UserData {
    data: SearchResult[]
}

export interface AnalysisData {
    data: SearchResult
}

export class PreviousAnalysisData {
    data: PreviousAnalysis[]
}

export class PreviousAnalysis {
    userId: string;
    analysisId: string;
    analysisKey: string;
}

export class AnalysisKey {
    userId: string;
    analysisId: string;
    analysisDate?: string;
    analysisType: string;
    analysisSubtype?: string;
    analyteNames?: string[];
    projectCode?: string;
    // projectCodeMV?: string;
    projectNames?: string;
    analysisTypeDisplay?: string;
    analysisSubtypeDisplay?: string;

}

export class RDSAnalysisData {
    user_id: string;
    organization_id: string;
    group_id: string;
    analysis_id: string;
    file_name: string;
    analysis_type: string;
    analysis_date?: string;
    project_code: string;
    analysis_status?: string;
}
export class RDSAuditData {
    user_id: string;
    organization_id: string;
    group_id: string;
    analysis_id: string;
    file_name: string;
    analysis_type: string;
    analysis_date?: string;
    project_code: string;
    analysis_status?: string;
}

export class AWSResponse {
    processcode: string;
    message: string;
    analysisID: string;
}



export class Dashboard {
    userId: string;
    analysisDate: Date;
    analyteNames: string[];
    analysisType: string;
    analysisSubtype?: string;
    projectCode?: string;
    // projectCodeMV?: string;
}

export class Summary {
    count: [];
    date: [];
    sumamry_array: AnalysisSummary[]

}

export class AnalysisSummary {
    userId: string;
    analysisId: string;
    overall_g: number;
    overall_y: number;
    overall_r: number;
    date: string;
    analytes: string[];
    projectCode: string;
    // projectCodeMV: string;
    analysisType: string;
    analysisSubtype: string;

}

export class Logs {
    data: AuthLog[];
    users: string[];
    file: string;
}
export class AuthLog {
    userId: string;
    // activityDate: string;
    // activityType: string;

    eventType: string;
    creationDate: string;
    eventResponse: string;
    // riskLevel: string;
    ipAddress: string;
    city: string;
}

export interface Table {
    analyte_name: string;
    table_type: string;
    table_index: string;
    table_title: string;
    analysis_type: string;
    rows: string;
    valid: boolean
}
export class Templates {
    type: string;
    description: string;
    columns: TemplateDetail[];
}
export class TemplateDetail {
    name: string;
    desc: string;
    type: string;
    keys: string[];
    required: boolean;
}


export class AnalysisDetailsADA {
    analysisType?: string;
    analysisSubtype?: string;
    projectCode?: string;
    regressionModel?: string;
    sampleMatrix?: string;
    antiCoagulant?: string;
    species?: string;
    samplePrep?: string;
    analyteNames: string[];
    analytes?: Analyte[];
    normalityTestData: string;
    screening_cutpoint: string;
    confirmatory_cutpoint: string;
    concentrationUnit: string;
    tukeyCoefficient: string[];
    nabDataset: string;
    multipleAnalytes: string;
    method_type: string;
    ada_subtype: string;
    ada_subtype_cutpoint: string;
    regressionType: string;
    PL: string;
    CVThreshold: string;
}

export class AnalysisDetailsSLR {
    analysisType?: string;
    analysisSubtype?: string;
    projectCode?: string;
    // projectCodeMV?: string;
    regressionModel?: string;
    lloq?: string;
    uloq?: string;
    re_type?: string;
    re_value?: string;
    lts80_dialog?: string;
    lts20_dialog?: string;
    extraction_dialog?: string;
    benchtop_dialog?: string;
    freeze_thaw_dialog?: string;
    multipleAnalytes?: string;
    first_patient_dialog?: string;
    sampleMatrix?: string;
    antiCoagulant?: string;
    species?: string;
    samplePrep?: string;
    sampleDate?: string;
    analyteNames?: string[];
    tableparamName?: string[];
    selectedValidationTableIds?: string[];
    selectedAssayTableIds?: string[];
    selectedGeneralTableIds?: string[];
    tableParamValue?: string[];
    files: [];
    file_path: string[];
    analyteName: string[];
    selectedGeneralTableName?: string[];
    selectedAssayTableName?: string[];
    selectedValidationTableName?: string[];
    thresholdLayout: string;

}

export class AnalysisDetailsqPCR {
    analysisType?: string;
    analysisSubtype?:string;
    study?:string;
    projectCode?: string;
    multipleAnalytes?: string;
    analyteNames?: string[];
    files: [];
    file_path: string[];
    analyteName: string[];
    LLOQ_ULOQ_Inter_prec_CV_Back_Calc: string;
    Gen_Inter_prec_CV_Back_Calc: string;
    Gen_Intra_Inter_Acc_RE_Back_Calc: string;
    ULOQ_LLOQ_Inter_Intra_RE_Back_Calc: string
    // Slope_cal_curve_reg:number;
    Lower_Slope_Value: string;
    Higher_Slope_Value: string;
    displayCq: boolean;
    displayBackCalculated: boolean;
    QualityControls: QualityControls;
    BackCalculated: BackCalculated;
    Cq: Cq;
    targetName: string;
    validators: boolean;
    group: string;
    organization: string;
    GraphData: string;
    removed_sample_id: string[];
    executionId: string;
    commentText: string;

}

export class DownloadFile {
    userId: string;
    analysisId: string;
    eventName: string;
    userName: string;
    filePath: string;
    userRole: string;
}


