import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { SharedService } from '../../../layouts/shared-service';
import { AnalysisDetails, AnalysisDetailsqPCR, Cq } from '../../../model/analysis-details.model';
import { DropDownService } from '../../../service/drop-down.service';
import { TemplateService } from '../../../service/template.service';
import { UploadService } from '../../../service/upload.service';
import { StorageService } from '../../../service/storage.service';

@Component({
  selector: 'app-qpcr-report',
  templateUrl: './qpcr-report.component.html',
  styleUrls: ['./qpcr-report.component.scss']
})
export class QpcrReportComponent implements OnChanges {

  @Input() analysistype: string;
  @Input() editvalues: any;
  @Output() formvalue = new EventEmitter<any>();
  pageTitle = 'Analyze Report/Data';

  analysisFormGroup: FormGroup;
  maxDate;
  // lts80_dialog: FormControl = new FormControl();
  analysisDetails: AnalysisDetails;
  subtype: AnalysisDetails;
  tableNamesList: any[];
  tableNames: any;
  showTable = false;
  countTable = false;
  post: any;
  tableParamValue = [];
  GenTablesList = [];
  ValTablesList = [];
  SumTablesList = [];
  Smparameterlist = [];
  NewSmparameterlist = [];
  Lmparameterlist = [];
  NewLmparameterlist = [];
  selectedValTables = [];
  selectedAssayTables = [];
  selectedGeneralTables = [];
  customdisable: boolean = true;
  disabledValue;
  Cq: boolean = false;
  BackCalculated: boolean = false;
  // cq:CQ;
  decimalNumberPattern = '^[0-9]+(\.[0-9]+)?$';


  constructor(
    private formBuilder: FormBuilder,
    private _sharedService: SharedService,
    private uploadService: UploadService,
    private dropDownService: DropDownService,
    private ref: ChangeDetectorRef,
    private storageService: StorageService
  ) {
    this._sharedService.emitChange(this.pageTitle);
   uploadService.disabledValue.subscribe(res => {
      this.disabledValue = res;

    })

  }

  ngOnChanges(changes: SimpleChanges) {

    if ('analysistype' in changes) {
      if (changes.analysistype.currentValue) {
        this.analysistype = changes.analysistype.currentValue;

      }

    }

    this.createForm();
  }


  createForm() {
    this.analysisFormGroup = this.formBuilder.group({
      analysisType: [this.analysistype],
      analysisSubtype: [null],
      study:[null],
      LLOQ_ULOQ_Inter_prec_CV_Back_Calc: [null, [Validators.pattern(this.decimalNumberPattern)]],
      Gen_Inter_prec_CV_Back_Calc: [null, [Validators.pattern(this.decimalNumberPattern)]],
      Gen_Intra_Inter_Acc_RE_Back_Calc: [null, [Validators.pattern(this.decimalNumberPattern)]],
      ULOQ_LLOQ_Inter_Intra_RE_Back_Calc: [null, [Validators.pattern(this.decimalNumberPattern)]],
      multipleAnalytes: ['False'],
      Lower_Slope_Value: this.formBuilder.control(-3.6, [Validators.pattern('^[-]?[0-9]+\.[0-9]+$')]),
      Higher_Slope_Value: this.formBuilder.control(-3.1, [Validators.pattern('^[-]?[0-9]+\.[0-9]+$')]),
      analyteNames: this.formBuilder.array([this.addAnalyteRows()]),
      files: [null],
      file_path: [null],
      analyteName: ['qPCR'],
      displayCq: [false],
      displayBackCalculated: [false],
      projectCode: [null],
      targetName: ['', [Validators.pattern('^[A-Za-z\x270-9 .,-_]+$')]],
      validators: this.atLeastOneCheckboxSelected(),
      BackCalculated: this.formBuilder.group({
        Gen_Intra_prec_CV_Back_Calc: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
        LLOQ_ULOQ_Intra_prec_CV_Back_Calc: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
      }),

      Cq: this.formBuilder.group({
        Intra_prec_CV_Cq: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
      }),




      QualityControls: this.formBuilder.group({
        LLOQ_QC: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
        Low_QC: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
        Mid_QC: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
        High_QC: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
        ULOQ_QC: this.formBuilder.control(null, [Validators.pattern(this.decimalNumberPattern)]),
      })
    });
    this.ref.detectChanges();

    if (this.editvalues !== undefined) {
      const qPCRvalue: AnalysisDetailsqPCR = new AnalysisDetailsqPCR()
      qPCRvalue.analysisType = this.analysistype;
      qPCRvalue.analysisSubtype = this.editvalues.analysisSubtype;
      qPCRvalue.study = this.editvalues.study;
      qPCRvalue.LLOQ_ULOQ_Inter_prec_CV_Back_Calc = this.editvalues.LLOQ_ULOQ_Inter_prec_CV_Back_Calc;
      qPCRvalue.Gen_Inter_prec_CV_Back_Calc = this.editvalues.Gen_Inter_prec_CV_Back_Calc;
      qPCRvalue.projectCode = this.editvalues.projectCode;
      qPCRvalue.multipleAnalytes = this.editvalues.multipleAnalytes;
      qPCRvalue.analyteNames = this.editvalues.analyteNames;
      qPCRvalue.file_path = this.editvalues.file_path;
      qPCRvalue.files = this.editvalues.files;
      qPCRvalue.analyteName = this.editvalues.analyteName;
      qPCRvalue.Gen_Intra_Inter_Acc_RE_Back_Calc = this.editvalues.Gen_Intra_Inter_Acc_RE_Back_Calc;
      qPCRvalue.ULOQ_LLOQ_Inter_Intra_RE_Back_Calc = this.editvalues.ULOQ_LLOQ_Inter_Intra_RE_Back_Calc;
      qPCRvalue.Higher_Slope_Value = this.editvalues.Higher_Slope_Value;
      qPCRvalue.Lower_Slope_Value = this.editvalues.Lower_Slope_Value;
      qPCRvalue.displayCq = this.editvalues.displayCq;
      qPCRvalue.displayBackCalculated = this.editvalues.displayBackCalculated;
      qPCRvalue.Cq = this.editvalues.Cq;
      qPCRvalue.BackCalculated = this.editvalues.BackCalculated;
      qPCRvalue.QualityControls = this.editvalues.QualityControls;
      qPCRvalue.targetName = this.editvalues.targetName;
      qPCRvalue.validators = this.editvalues.validators

      for (let i = 1; i < this.editvalues.analyteNames.length; i++) {
        this.analyteNames.push(this.addAnalyteRows());
      }
      this.analysisFormGroup.setValue(qPCRvalue);

    }

  }

  atLeastOneCheckboxSelected() {
    return (group: FormGroup): { [key: string]: boolean } | null => {
      const cq = group.get('displayCq').value;
      const backCalculated = group.get('displayBackCalculated').value;

      if (!cq && !backCalculated) {
        return { 'atLeastOneCheckboxRequired': true };
      }

      return null;
    }

  }


  updateValidity(controlName: string) {
    const control = this.analysisFormGroup.get(controlName);
    const checkboxValue = control.value;
    const correspondingControl = this.analysisFormGroup.get(`${controlName}.Intra_prec_CV_Cq`);

    if (checkboxValue?.Intra_prec_CV_Cq == '') {
      correspondingControl.reset();
      correspondingControl.markAsPristine();
      correspondingControl.clearValidators();
      correspondingControl.updateValueAndValidity();
    }

    if ((this.analysisFormGroup.get('displayCq').value == false && this.analysisFormGroup.get('Cq.Intra_prec_CV_Cq').value == null)) {
      correspondingControl.clearValidators(); 
      this.analysisFormGroup.updateValueAndValidity();

    } else {
      this.analysisFormGroup.setValidators(this.atLeastOneCheckboxSelected());
     
    }
    correspondingControl.updateValueAndValidity();
    this.analysisFormGroup.updateValueAndValidity();
  }

  updateValidityBackCalc(controlName: string) {
    const control = this.analysisFormGroup.get(controlName);
    const checkboxValue = control.value;
    const correspondingControl1 = this.analysisFormGroup.get(`${controlName}.Gen_Intra_prec_CV_Back_Calc`);
    const correspondingControl2 = this.analysisFormGroup.get(`${controlName}.LLOQ_ULOQ_Intra_prec_CV_Back_Calc`);

    if (checkboxValue?.Gen_Intra_prec_CV_Back_Calc == '') {
      correspondingControl1.reset();
      correspondingControl1.markAsPristine();
      correspondingControl1.clearValidators();
      correspondingControl1.updateValueAndValidity();
    }

    if (checkboxValue?.LLOQ_ULOQ_Intra_prec_CV_Back_Calc == '') {
      correspondingControl2.reset();
      correspondingControl2.markAsPristine();
      correspondingControl2.clearValidators();
      correspondingControl2.updateValueAndValidity();
    }

    if (this.analysisFormGroup.get('displayBackCalculated').value == false && checkboxValue.Gen_Intra_prec_CV_Back_Calc == null && checkboxValue.LLOQ_ULOQ_Intra_prec_CV_Back_Calc == null) {
      correspondingControl1.clearValidators();
      correspondingControl2.clearValidators();
      this.analysisFormGroup.updateValueAndValidity();
    
    }
    else {
      this.analysisFormGroup.setValidators(this.atLeastOneCheckboxSelected());
  
    }
    correspondingControl1.updateValueAndValidity();
    correspondingControl2.updateValueAndValidity();
    this.analysisFormGroup.updateValueAndValidity();

  }


  get formControls() { return this.analysisFormGroup.controls; }

  onSubmit(post: any) {

    this.analysisDetails = Object.assign({}, this.analysisFormGroup.value);
    if (this.analysisDetails.displayCq === false) {
      this.analysisDetails.Cq.Intra_prec_CV_Cq = null;
    }
    this.analysisDetails.userId = this.storageService.get('username');

    // if (this.analysisDetails.multipleAnalytes === 'Yes') {
    //   this.analysisDetails.multipleAnalytes = 'True'
    // } else {
    //   this.analysisDetails.multipleAnalytes = 'False'
    // }

    this.analysisDetails.analysisId = (new Date).getTime().toString();

    this.analysisDetails.analyteName = this.analyteNames.value.map(res =>
      res = res.analyteName)
    this.post = post;

    this.formvalue.emit(this.analysisDetails);



  }

  addAnalyteRows() {
    return this.formBuilder.group({
      analyteName: ['qPCR', [Validators.pattern('^[A-Za-z\x270-9 .,-]+$')]],
      LLOQValue: [''],
      ULOQValue: [''],
    })
  }


  getDropDownValues(key) {
    return this.dropDownService.dropDownsMap.get(key);
  }

  get analyteNames() {
    return this.analysisFormGroup.get('analyteNames') as FormArray;
  }

  addAnalyte() {
    this.analyteNames.push(this.addAnalyteRows());

  }

  removeAnalyte(index) {
    this.analyteNames.removeAt(index);
  }



  multipleAnalyteValues() {
    const multipleChecked = this.analysisFormGroup.value.multipleAnalytes;
    const count = this.analysisFormGroup.value.analyteNames;

    if (multipleChecked === 'True' && count.length === 1) {

      // tslint:disable-next-line: no-unused-expression
      this.analysisFormGroup.invalid;
    } else {

    }

  }



}